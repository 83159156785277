import { type ComponentPropsWithoutRef, useEffect, useRef } from 'react'
import type { ExternalScriptsHandle } from 'remix-utils/external-scripts'
import { z } from 'zod'
import { zx } from 'zodix'

import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import type { DataFunctionArgs } from '@remix-run/node'
import { json, redirect } from '@remix-run/node'
import {
  Form,
  isRouteErrorResponse,
  Outlet,
  useActionData,
  useLoaderData,
  useMatches,
  useNavigation,
  useRouteError,
} from '@remix-run/react'
import * as Sentry from '@sentry/remix'

import Footer from '~/components/Footer'
import { Header } from '~/components/Header'
import { addToWaitlist } from '~/models/waitlist.server'
import { created, serverError } from '~/responses'
import { getPostHogCookie, posthog } from '~/services/posthog.server'
import { classNames, getClientEnv } from '~/utils'

export { links } from '~/links'

export let handle: ExternalScriptsHandle = {
  scripts: [
    {
      src: 'https://challenges.cloudflare.com/turnstile/v0/api.js',
      async: true,
      defer: true,
    },
  ],
}

export async function action({ request }: DataFunctionArgs) {
  const ip = request.headers.get('CF-Connecting-IP')
  const postHogCookie = getPostHogCookie({ request })
  const submission = await zx.parseFormSafe(request, {
    email: z.string().email(),
    placement: z.string(),
    'cf-turnstile-response': z.string(),
  })

  if (!submission.success) {
    const placement = (await request.formData()).get('placement')
    return json(
      {
        placement,
        errors: {
          //@ts-expect-error - Not sure why this works fine but throws type error
          email: (submission.error.formErrors.fieldErrors.email as string)
            ? 'Email is invalid'
            : null,
          //@ts-expect-error - Not sure why this works fine but throws type error
          otp: (submission.error.formErrors.fieldErrors.otp as string)
            ? 'OTP is invalid'
            : null,
        },
      },
      { status: 400 }
    )
  }

  try {
    // Validate the token by calling the
    // "/siteverify" API endpoint.
    let formData = new FormData()
    formData.append('secret', process.env.CF_TURNSTILE_SECRET as string)
    formData.append('response', submission.data['cf-turnstile-response'])
    if (ip) formData.append('remoteip', ip)

    const url = 'https://challenges.cloudflare.com/turnstile/v0/siteverify'
    const result = await fetch(url, {
      body: formData,
      method: 'POST',
    })

    const outcome = await result.json()
    if (!outcome.success) throw new Error(outcome['error-codes'])
  } catch (error) {
    console.error(error)
    throw serverError({
      placement: submission.data.placement,
      errors: {
        email: 'Invalid captcha, please try again.',
      },
    })
  }

  try {
    const { identity, verification } = await addToWaitlist(
      submission.data.email
    )

    // If we have an ID, track the event
    if (postHogCookie?.distinct_id) {
      posthog.capture({
        distinctId: postHogCookie?.distinct_id,
        event: 'joined waitlist',
        properties: {
          $set: { email: submission.data.email },
        },
      })
    }

    if (verification.status === 'PENDING') {
      return redirect(
        `/products/remind/waitlist/verify?identity=${identity.id}`
      )
    }

    // eslint-disable-next-line prettier/prettier
    const message = 'Thanks, we\'ve added you to the waitlist!'

    return created({
      success: true,
      placement: submission.data.placement,
      message,
      identity,
    })
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
    throw serverError({
      placement: submission.data.placement,
      errors: {
        email: 'Waitlist full, but you can email us at hello@snipbot.com',
      },
    })
  }
}

export function loader() {
  return getClientEnv()
}

export default function WaitlistView() {
  const matches = useMatches()
  const isVerify =
    matches.at(-1)?.id === 'routes/products.remind.waitlist.verify'
  return (
    <div className="flex flex-col h-full">
      <Header />
      <div className="flex-1 px-4 pt-16 pb-20 bg-brand-off-white sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="relative max-w-lg mx-auto divide-y-2 divide-neutral-200 lg:max-w-7xl">
          <div>
            <h1 className="text-3xl font-cursive text-neutral-900 sm:text-4xl">
              Join our waitlist
            </h1>
            {isVerify ? (
              <p className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:gap-5 lg:items-center">
                We've sent you an email, enter the code below to continue.
              </p>
            ) : (
              <p className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:gap-5 lg:items-center">
                Snipbot is an embeddable reminder-me-later widget for your
                website visitors. Secure your spot in the waitlist and be the
                first to get access.
              </p>
            )}

            {!isVerify ? <Waitlist placement="page" /> : null}
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export function Waitlist({
  placement,
  className = '',
}: ComponentPropsWithoutRef<'form'> & { placement: string }) {
  const { CF_SITE_KEY } = useLoaderData<typeof loader>()
  const actionData = useActionData<typeof action>()
  const { state, formMethod } = useNavigation()

  let isActionSubmission =
    formMethod != null &&
    ['POST', 'PUT', 'PATCH', 'DELETE'].includes(formMethod)

  const isWaitlistPending = state !== 'idle' && isActionSubmission

  const hasEmailError =
    state === 'idle' &&
    actionData &&
    'errors' in actionData &&
    'email' in actionData.errors

  const isSuccess = state === 'idle' && actionData && 'success' in actionData

  const emailRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (hasEmailError) {
      console.log(emailRef.current)

      emailRef.current?.focus()
    }
  }, [actionData])

  return (
    <Form
      method="post"
      className={classNames(
        'mt-6 sm:max-w-lg sm:w-full sm:flex flex-wrap',
        className
      )}
      action="/products/remind/waitlist"
      noValidate
    >
      <input name="placement" value={placement} type="hidden" />
      <div className="flex-1 min-w-0">
        <label htmlFor={`${placement}-email`} className="sr-only">
          Email address
        </label>
        <div className="relative">
          <input
            id={`${placement}-email`}
            type="email"
            name="email"
            ref={emailRef}
            disabled={isWaitlistPending}
            className="block w-full px-5 py-3 text-base border rounded-md shadow-sm placeholder-neutral-500 border-neutral-300 text-neutral-800 focus:border-orange-500 focus:ring-orange-500 disabled:bg-opacity-25 disabled:bg-neutral-500 disabled:border-orange-200"
            placeholder="Enter your email"
            autoComplete="email"
            aria-invalid={hasEmailError ? true : undefined}
            aria-describedby="email-error"
            required
          />
          {hasEmailError && (
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <ExclamationCircleIcon
                className="w-5 h-5 text-red-700"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
        {hasEmailError ? (
          <div className="pt-1 pl-3 text-red-700" id="email-error">
            {actionData.errors.email}
          </div>
        ) : null}
        {isSuccess ? (
          <div className="pt-1 pl-3 text-green-500" id="email-confirmation">
            {actionData.message}
          </div>
        ) : null}
      </div>

      <div className="mt-4 sm:mt-0 sm:ml-3">
        <button
          type="submit"
          className="flex items-center justify-center w-full px-5 py-3 text-base font-medium text-white uppercase bg-orange-500 border border-transparent rounded-md shadow md:w-60 font-cursive hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:px-10"
        >
          {isWaitlistPending ? (
            <svg
              className="w-6 h-6 mr-3 -ml-1 text-white animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            'Join the waitlist'
          )}
        </button>
      </div>
      <div
        className="cf-turnstile w-full mt-3"
        data-sitekey={CF_SITE_KEY}
      ></div>
    </Form>
  )
}

export function ErrorBoundary() {
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    return (
      <div className="flex flex-col h-full">
        <Header />
        <div className="flex-1 px-4 pt-16 pb-20 bg-brand-off-white sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
          <div className="relative max-w-lg mx-auto divide-y-2 divide-neutral-200 lg:max-w-7xl">
            <div>
              <h1 className="text-3xl font-cursive text-neutral-900 sm:text-4xl">
                Sorry, waitlist full
              </h1>
              <p className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:gap-5 lg:items-center">
                You can send us an email to support@snipbot.com if you would
                still like to join.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}
